import React from 'react';
import Placeholder from '../../../core/Components/Placeholder';

const FiftySplitContainer = ({ rendering }) => {
  return (
    <div className="sl l-padding is-50-split">
      <div className="sl-list has-2-items">
        <div className="sl-item">
          <Placeholder name="content-50-split-left" rendering={rendering} />
        </div>
        <div className="sl-item">
          <Placeholder name="content-50-split-right" rendering={rendering} />
        </div>
      </div>
    </div>
  );
};

export default FiftySplitContainer;
